import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import MainNav from "core/containers/main-nav-container";
import LogoHeader from "core/containers/logo-header-container";
import classes from "./core-view.scss";
import "styles/core.scss";
import AlertAuthWarning from "containers/alert-auth-warning-container";
import AlertAuthExpired from "containers/alert-auth-expired-container";
import MessageBanner from "containers/message-banner-container";
import { injectIntl, defineMessages, useIntl } from "react-intl";
import mt from "core/message-translations";
import Unauthorized from "routes/unauthorized/containers/unauthorized-container";
import ErrorView from "routes/error/containers/error-container";
import PersonalInfo from "routes/personal-info/containers/personal-info-container";
import SigninSecurity from "routes/signin-security/containers/signin-security-container";
import DeveloperTools from "routes/developer-tools/containers/developer-tools-container";
import CreateTokens from "routes/developer-tools/containers/create-token-container";
import UserInvitation from "routes/user-invite/containers/user-invitation-container";
import AcceptInvitation from "routes/accept-invitation/containers/accept-invitation-container";
import { showToastrMessage, isUserInvitationPage } from "lib/utility-functions";
import environment from "lib/environment";
import auth from "lib/profile-auth";
import history from "lib/history";
import resolveRoute from "lib/resolve-route";
import ConfirmDialog from "containers/confirm-dialog-container";
import SideNav from "core/containers/side-nav-container";
import Notification from "./notificaton-view";


const t = defineMessages({
  "core.skip.to.main.content": {
    id: "core.skip.to.main.content",
    defaultMessage: "Skip to main content",
    description:
      "Skip link to main content to bypass navigation links for better accessibility",
  },
});

const Core = ({
  alertAuthWarningIsVisible,
  alertAuthExpiredIsVisible,
  messageBannerVisible,
  toastrMessage,
  confirmDialog,
  useStandardNav,
}) => {
  const intl = useIntl();
  /* istanbul ignore next */
  if (isUserInvitationPage()) {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get("id")) {
      window.location.href = `${environment.get().myAccount_url}`;
      return null;
    }
    if (auth.auth && urlParams.get("id")) {
      history.push(
        resolveRoute("acceptInvitation", {
          search: `?id=${urlParams.get("id")}`,
        })
      );
      return null;
    }
  }
  return (
    <div id="app-container">
      {confirmDialog &&
        confirmDialog.yesCallbackFn &&
        confirmDialog.noCallbackFn && <ConfirmDialog />}
      <div className="app-container-inner">
        <div className={classes["skipToMainContent"]}>
          <a href="#mainContent">
            {intl.formatMessage(t["core.skip.to.main.content"])}
          </a>
        </div>
        {alertAuthWarningIsVisible && <AlertAuthWarning />}
        {alertAuthExpiredIsVisible && <AlertAuthExpired />}
        {messageBannerVisible && <MessageBanner />}
        {useStandardNav && <MainNav />}
        {!useStandardNav && <LogoHeader />}
        <div className={classes["fluidAppContainer"]}>
          {useStandardNav && <SideNav />}
          <div className={classes['mainDisplay']}>
            {toastrMessage && toastrMessage.type && toastrMessage.messageId &&
              <div className={classes["notificationContainer"]}>
                <Notification
                  toastrType={toastrMessage.type}
                  toastrMessage={intl.formatMessage(mt[toastrMessage.messageId], toastrMessage.params)}
                  toastrMessageId={toastrMessage.messageId}
                />
              </div>}
            <Switch>
              <Redirect exact from="/" to="/personal-info" />
              <Route exact path="/personal-info" component={PersonalInfo} />
              <Route exact path="/signin-security" component={SigninSecurity} />
              <Route
                exact
                path="/developer-tools/tokenedit/:tokenId?"
                component={CreateTokens}
              />
              <Route exact path="/developer-tools" component={DeveloperTools} />
              <Route exact path="/userinvitation" component={UserInvitation} />
              <Route
                exact
                path="/acceptinvitation"
                component={AcceptInvitation}
              />
              <Route exact path="/unauthorized" component={Unauthorized} />
              <Route exact path="/error" component={ErrorView} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

Core.propTypes = {
  alertAuthWarningIsVisible: PropTypes.bool,
  alertAuthExpiredIsVisible: PropTypes.bool,
  messageBannerVisible: PropTypes.bool,
  useStandardNav: PropTypes.bool.isRequired,
  toastrMessage: PropTypes.shape({
    type: PropTypes.string,
    messageId: PropTypes.string,
    params: PropTypes.object,
  }),
  confirmDialog: PropTypes.shape({
    yesCallbackFn: PropTypes.func,
    noCallbackFn: PropTypes.func,
  }),
};

export default withRouter(injectIntl(Core));
