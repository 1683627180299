import {
  daoSocialAccountsByUserIdGet,
  daoSocialSignInLinksGet,
  daoSocialAccountByUserIdDelete,
} from 'dao/socialaccounts-dao';
import appconfig from 'config/appconfig';

// ------------------------------------
// Constants
// ------------------------------------
export const SOCIAL_ACCOUNTS_GET = 'SOCIAL_ACCOUNTS_GET';
export const SOCIAL_ACCOUNTS_GET_SUCCESS = 'SOCIAL_ACCOUNTS_GET_SUCCESS';
export const SOCIAL_ACCOUNTS_GET_FAILURE = 'SOCIAL_ACCOUNTS_GET_FAILURE';

export const SOCIAL_SIGN_IN_LINKS_GET = 'SOCIAL_SIGN_IN_LINKS_GET';
export const SOCIAL_SIGN_IN_LINKS_GET_SUCCESS = 'SOCIAL_SIGN_IN_LINKS_GET_SUCCESS';
export const SOCIAL_SIGN_IN_LINKS_GET_FAILURE = 'SOCIAL_SIGN_IN_LINKS_GET_FAILURE';

export const SOCIAL_PROVIDER_DELETE = 'SOCIAL_PROVIDER_DELETE';
export const SOCIAL_PROVIDER_DELETE_FAILURE = 'SOCIAL_PROVIDER_DELETE_FAILURE';

export const SOCIAL_DISCONNECT_LAST_ACCOUNT = 'SOCIAL_DISCONNECT_LAST_ACCOUNT';
export const SOCIAL_DISCONNECTED_ACCOUNT_NAME = 'SOCIAL_DISCONNECTED_ACCOUNT_NAME';
export const SOCIAL_RECONNECT_ACCOUNT = 'SOCIAL_RECONNECT_ACCOUNT';


// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  socialAccounts: [],
  disconnectLastSocialAccount: '',
  disconnectedSocialAccountName: '',
  reconnectSocialAccount: '',

  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const socialIsLoading = (state) => state.social.isLoading;
export const socialAccountsData = (state) => state.social.socialAccounts;
export const socialDisconnectLastAccount = (state) => state.social.disconnectLastSocialAccount;
export const socialReconnectAccount = (state) => state.social.reconnectSocialAccount;
export const socialDisconnectedAccountName = (state) => state.social.disconnectedSocialAccountName;
export const socialSignInLinks = (state) => {
  const objLinks = {};
  objLinks[appconfig.socialAccountLinks.GOOGLE] = '';
  objLinks[appconfig.socialAccountLinks.FACEBOOK] = '';
  objLinks[appconfig.socialAccountLinks.LINKEDIN] = '';
  objLinks[appconfig.socialAccountLinks.MICROSOFT] = '';
  objLinks[appconfig.socialAccountLinks.LASTPASS] = '';
  objLinks[appconfig.socialAccountLinks.APPLE] = '';

  const signInLinks = state.social.socialSignInLinks;
  if (typeof signInLinks !== 'undefined') {
    objLinks[appconfig.socialAccountLinks.GOOGLE] = signInLinks[appconfig.socialAccountLinks.GOOGLE] || '';
    objLinks[appconfig.socialAccountLinks.FACEBOOK] = signInLinks[appconfig.socialAccountLinks.FACEBOOK] || '';
    objLinks[appconfig.socialAccountLinks.LINKEDIN] = signInLinks[appconfig.socialAccountLinks.LINKEDIN] || '';
    objLinks[appconfig.socialAccountLinks.MICROSOFT] = signInLinks[appconfig.socialAccountLinks.MICROSOFT] || '';
    objLinks[appconfig.socialAccountLinks.LASTPASS] = signInLinks[appconfig.socialAccountLinks.LASTPASS] || '';
    objLinks[appconfig.socialAccountLinks.APPLE] = signInLinks[appconfig.socialAccountLinks.APPLE] || '';
  }
  return objLinks;
};

export const socialAccountsByProvider = (state, provider) => {
  const socialAccount = socialAccountsData(state).find((socialAcct) => socialAcct[provider]);
  return typeof socialAccount !== 'undefined' ? socialAccount : {};
};

// ------------------------------------
// Actions
// ------------------------------------

// Social Account list request
export const socialAccountsGetSuccess = (payload = {}) => ({
  type: SOCIAL_ACCOUNTS_GET_SUCCESS,
  payload,
});

export const socialAccountsGetFailure = () => ({
  type: SOCIAL_ACCOUNTS_GET_FAILURE,
});

export const socialAccountsByUserIdGet = (userId) => (dispatch) => {
  dispatch({
    type: SOCIAL_ACCOUNTS_GET,
  });

  return daoSocialAccountsByUserIdGet(userId)
    .then(
      response => dispatch(socialAccountsGetSuccess(response.data)),
      ex => dispatch(socialAccountsGetFailure(ex)),
    );
};

// Social Sign In Links request
export const socialSignInLinksGetSuccess = (payload = {}) => ({
  type: SOCIAL_SIGN_IN_LINKS_GET_SUCCESS,
  payload,
});

export const socialSignInLinksGetFailure = () => ({
  type: SOCIAL_SIGN_IN_LINKS_GET_FAILURE,
});

export const socialSignInLinksGet = (params = {}) => (dispatch) => {
  dispatch({
    type: SOCIAL_SIGN_IN_LINKS_GET,
  });

  return daoSocialSignInLinksGet(params)
    .then(
      response => dispatch(socialSignInLinksGetSuccess(response.data)),
      ex => dispatch(socialSignInLinksGetFailure(ex)),
    );
};

export const setModeDisconnectLastSocialAccount = (accountName = '') => ({
  type: SOCIAL_DISCONNECT_LAST_ACCOUNT,
  accountName,
});

export const setDisconnectedSocialAccountName = (payload = '') => ({
  type: SOCIAL_DISCONNECTED_ACCOUNT_NAME,
  payload,
});

export const setModeReconnectSocialAccount = (accountName = '') => ({
  type: SOCIAL_RECONNECT_ACCOUNT,
  accountName,
});

// Social Provider Delete request
export const socialProviderByUserIdDeleteFailure = () => ({
  type: SOCIAL_PROVIDER_DELETE_FAILURE,
});

export const socialProviderByUserIdDelete = (userId, accountName) => (dispatch) => {
  dispatch({
    type: SOCIAL_PROVIDER_DELETE,
  });

  return daoSocialAccountByUserIdDelete(userId, accountName)
    .then(
      () => {
        // Add a second delay for calling socialAccountsByUserIdGet() so that the DB can update the values.
        setTimeout(() => { dispatch(socialAccountsByUserIdGet(userId)); }, 1000);
        dispatch(setModeDisconnectLastSocialAccount(''));
      },
      ex => dispatch(socialProviderByUserIdDeleteFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SOCIAL_ACCOUNTS_GET]: (state) => ({...state, isLoading: true}),
  [SOCIAL_ACCOUNTS_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        socialAccounts: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [SOCIAL_ACCOUNTS_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [SOCIAL_SIGN_IN_LINKS_GET]: (state) => ({...state, isLoading: true}),
  [SOCIAL_SIGN_IN_LINKS_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        socialSignInLinks: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [SOCIAL_SIGN_IN_LINKS_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SOCIAL_PROVIDER_DELETE]: (state) => ({...state, isLoading: true}),
  [SOCIAL_PROVIDER_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
    disconnectLastSocialAccount: '',
  }),

  [SOCIAL_DISCONNECT_LAST_ACCOUNT]: (state, action) => {
    if (typeof action === 'object') {
      return {
        ...state,
        disconnectLastSocialAccount: action.accountName,
      };
    }
    return {...state, disconnectLastSocialAccount: ''};
  },
  [SOCIAL_DISCONNECTED_ACCOUNT_NAME]: (state, action) => {
    if (typeof action === 'object') {
      return {
        ...state,
        disconnectedSocialAccountName: action.payload,
      };
    }
    return {...state, disconnectedSocialAccountName: ''};
  },
  [SOCIAL_RECONNECT_ACCOUNT]: (state, action) => {
    if (typeof action === 'object') {
      return {
        ...state,
        reconnectSocialAccount: action.payload,
      };
    }
    return {...state, reconnectSocialAccount: ''};
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const socialProvidersReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default socialProvidersReducer;
