import React from "react";
import PropTypes from "prop-types";
import EmailEditorContainer from "../containers/email-editor-container";
import PasswordEditorContainer from "../containers/password-editor-container";
import EnhancedSecurityContainer from "../containers/enhanced-security-container";
import SocialAccountsContainer from "../containers/social-accounts-container";
import DevicesEditorContainer from "../containers/devices-editor-container";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import st from "core/shared-translations";
import commonClasses from "styles/_base.scss";

const t = defineMessages({
  "signin-security.icon-security": {
    id: "signin-security.icon-security",
    defaultMessage: "Sign In & Security logo",
    description:
      "Hover text to display for the Sign In and Security Lock image",
  },
});

const SignInSecurity = ({
  isOrganizationMember,
  socialLoginEnabled,
  socialAccounts,
  socialSignInLinks,
  enterpriseSignInEnabled,
  forceSamlSsoLogin,
  openDevicesDialogOnLoad,
}) => {
  return (
    <div id="signinSecurityContainer">
      <Typography variant="heading-large" className={commonClasses["pageHeader"]}>
        <FormattedMessage {...st["nav.navbar.signinsecurity"]} />
      </Typography>
      <div className={commonClasses["mainContainer"]}>
        <div className={commonClasses["singleColumn"]}>
          <EmailEditorContainer />
          {!forceSamlSsoLogin &&
            <PasswordEditorContainer
              socialSignInLinks={socialSignInLinks}
            />}
        </div>
        {socialLoginEnabled && (
          <div className={commonClasses["singleColumn"]}>
            <SocialAccountsContainer
              socialAccounts={socialAccounts}
              socialSignInLinks={socialSignInLinks}
            />
          </div>
        )}
        <div className={commonClasses["singleColumn"]}>
          <DevicesEditorContainer />
          <EnhancedSecurityContainer />
        </div>
      </div>
    </div>
  );
};

SignInSecurity.propTypes = {
  isOrganizationMember: PropTypes.bool.isRequired,
  enterpriseSignInEnabled: PropTypes.bool.isRequired,
  socialLoginEnabled: PropTypes.bool.isRequired,
  socialAccounts: PropTypes.array.isRequired,
  socialSignInLinks: PropTypes.object.isRequired,
  forceSamlSsoLogin: PropTypes.bool.isRequired,
  openDevicesDialogOnLoad: PropTypes.bool.isRequired,
};

export default injectIntl(SignInSecurity);
