import React from 'react';
import PropTypes from 'prop-types';
import st from 'core/shared-translations';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  defineMessages,
  injectIntl,
} from 'react-intl';
import classes from './signin-security.scss';
import { Typography, Button, InputField } from '@getgo/chameleon-web-react-wrapper';
import { SendOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  'email-verification.verification-code': {
    id: 'email-verification.verification-code',
    defaultMessage: 'Verification code',
    description: 'Field label for email Verification code',
  },
  'email-verification.user-instruction': {
    id: 'email-verification.user-instruction',
    defaultMessage: 'We just sent a confirmation email to <b>{emailAddress}</b> with a verification code. Please enter it below.',
    description: 'Instruction for the user to look for, and enter the verification email in the field below',
  },
  'email-verification.number-entry-validation-failure': {
    id: 'email-verification.number-entry-validation-failure',
    defaultMessage: 'Enter a valid verification code with all numerals (for example, 123456).',
    description: 'Instruction for the user to enter numeric values only as a validation code',
  },
  'email-verification.entry-validation-failure': {
    id: 'email-verification.entry-validation-failure',
    defaultMessage: 'That\'s an invalid verification code. Check your email and try again.',
    description: 'Instruction for the user to enter the correct a validation code after validation failure',
  },
  'email-verification.token-timeout-failure': {
    id: 'email-verification.token-timeout-failure',
    defaultMessage: 'That code has expired. Please check your email for a new code.',
    description: 'Instruction for the user to check email for new verification code',
  },
  'email-verification.info-message': {
    id: 'email-verification.info-message',
    defaultMessage: 'If you don\'t get an email within 5 minutes, check your spam & trash folders. If you don\'t find it, we can ',
    description: 'Information message to the user to check email and enter verification code or resend validation email.',
  },
  'email-verification.resend-verification-email-link-text': {
    id: 'email-verification.resend-verification-email-link-text',
    defaultMessage: 'resend the email',
    description: 'Information message to the user to check email and enter verification code or resend validation email.',
  },
});

export const EmailVerificationEditor = ({
  intl,
  showEmailEditor,
  onBlur,
  onChange,
  onConfirm,
  enableSubmit,
  isLoading,
  errors,
  userName,
  verificationCode,
  handlePrimaryEmailVerification,
}) => (
  <div id="email-verification-content" className={classes["emailVerificaionContainer"]}>
    <Typography variant="body-medium">
      <FormattedHTMLMessage {...t['email-verification.user-instruction']} values={{ emailAddress: userName }} />
    </Typography>
    <InputField
      name="verificationCode"
      value={verificationCode}
      onChange={onChange}
      onBlur={onBlur}
      fullwidth
      error={!!errors.verificationCode}
      helperText={
        !!errors.verificationCode &&
        intl.formatMessage(st[errors.verificationCode] || t[errors.verificationCode])
      }
    >
      {intl.formatMessage(t['email-verification.verification-code'])}
    </InputField>

    <Typography variant="body-small">
      <FormattedMessage {...t['email-verification.info-message']} />
    </Typography>
    <Button
      variant="secondary"
      trailingIcon={<SendOutlinedIcon />}
      onClick={handlePrimaryEmailVerification}
    >
      <FormattedMessage {...t['email-verification.resend-verification-email-link-text']} />
    </Button>
    <div id="cta" className={classes["ctaSectionConatiner"]}>
      <div className={classes["ctaSection"]}>
        <Button
          id="email-verification-confirm-button"
          disabled={!enableSubmit}
          onClick={onConfirm}
        >
          {isLoading ?
            <FormattedMessage {...st['shared.confirming']} /> :
            <FormattedMessage {...st['shared.confirm']} />}
        </Button>
        <Button
          id="email-verification-back-button"
          onClick={showEmailEditor}
          variant="tertiary"
        >
          <FormattedMessage {...st['shared.back']} />
        </Button>
      </div>
    </div>
  </div>
);

EmailVerificationEditor.propTypes = {
  intl: PropTypes.object.isRequired,
  personData: PropTypes.object.isRequired,
  showEmailEditor: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  verificationCode: PropTypes.string,
  userName: PropTypes.string,
  handlePrimaryEmailVerification: PropTypes.func.isRequired,
};

export default injectIntl(EmailVerificationEditor);
