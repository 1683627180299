/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Typography, AvatarV2 } from "@getgo/chameleon-web-react-wrapper";
import classes from "./boxed-container-view.scss";
import { ChevronRightOutlinedIcon } from '@getgo/chameleon-icons/react';

export const BoxedContainer = ({
  title,
  children,
  buttonText,
  onClick,
  buttonIcon,
  showCTAButton = true,
  disabled = false,
  boxIcon
}) => {
  return (
    <Fragment>
      <div className={classes['mainBoxedContainer']}>
        <div className={classes['header']}>
          <Typography tag="h2" variant="heading-small">
            {title}
          </Typography>
          {!!showCTAButton &&
            <Button
              role="button"
              onClick={onClick}
              leadingIcon={buttonIcon}
              variant="secondary"
              disabled={disabled}
            >
              {buttonText}
            </Button>}
        </div>
        {children}
      </div>
      <div className={classes["mainBoxedContainerMobileView"]}>
        <AvatarV2 size="large" variant="secondary" label={title}>
          {boxIcon}
        </AvatarV2>
        <div className={classes["expandableSection"]} onClick={onClick}>
          <Typography variant="body-large">{title}</Typography>
          <ChevronRightOutlinedIcon />
        </div>
      </div>
    </Fragment>
  );
};

BoxedContainer.propTypes = {
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  title: PropTypes.object.isRequired,
  buttonIcon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  showCTAButton: PropTypes.bool,
  disabled: PropTypes.bool,
  boxIcon: PropTypes.object,
  children: PropTypes.object.isRequired
};

export default BoxedContainer;
