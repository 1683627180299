import { defineMessages } from 'react-intl';

const t = defineMessages({
  'personal-info.data-save-successful': {
    id: 'personal-info.data-save-successful',
    defaultMessage: 'Your profile info was saved.',
  },
  'personal-info.data-save-failed': {
    id: 'personal-info.data-save-failed',
    defaultMessage: 'We could not save your profile info. Please try again.',
  },
  'avatar-selector.reset-to-default-photo_successful': {
    id: 'avatar-selector.reset-to-default-photo_successful',
    defaultMessage: 'Your photo was reset to a default image.',
    description: 'Success message to be displayed for submission of reset photo request.',
  },
  'avatar-selector.reset-to-default-photo_failed': {
    id: 'avatar-selector.reset-to-default-photo_failed',
    defaultMessage: 'We could not reset your photo to a default image. Please try again.',
    description: 'Failure message to be displayed for unsuccessful submission of reset photo request.',
  },
  'avatar-selector.avatar-upload-successful': {
    id: 'avatar-selector.avatar-upload-successful',
    defaultMessage: 'Your photo uploaded. It may take a few minutes to show up elsewhere in the system.',
    description: 'Success message to be displayed for completed photo upload.',
  },
  'avatar-selector.avatar-upload-failed': {
    id: 'avatar-selector.avatar-upload-failed',
    defaultMessage: 'We could not upload your photo. Please try again.',
    description: 'Failure message to be displayed for failed photo upload',
  },
  'password-editor.password-change-successful': {
    id: 'password-editor.password-change-successful',
    defaultMessage: 'Your password has been updated.',
    description: 'Content toastr (popup message) displayed when the user-entered password was successfully changed.',
  },
  'password-editor.password-save-successful': {
    id: 'password-editor.password-save-successful',
    defaultMessage: 'Your password has been saved.',
    description: 'Content toastr (popup message) displayed when the user-entered password was successfully saved.',
  },
  'email-editor.verification-email-send-successful': {
    id: 'email-editor.verification-email-send-successful',
    defaultMessage: 'An email was sent to your chosen email account. Check your email for the verification code.',
    description: 'Content toastr (popup message) when the verification mail has been successfully re-sent',
  },
  'email-editor.verification-email-send-failed': {
    id: 'email-editor.verification-email-send-failed',
    defaultMessage: 'We could not send the verification email. Please try later.',
    description: 'Content toastr (popup message) when the verification mail was not resent do an error.',
  },
  'email-editor.recovery-email-save-successful': {
    id: 'email-editor.recovery-email-save-successful',
    defaultMessage: 'Your recovery email address has been updated.',
    description: 'Content toastr (popup message) displayed when the user-entered recovery email was successfully saved.',
  },
  'email-editor.recovery-email-save-failed': {
    id: 'email-editor.recovery-email-save-failed',
    defaultMessage: 'We could not update your recovery email address. Please try again.',
    description: 'Content toastr (popup message) displayed when the user-entered recovery email was not saved due to an error.',
  },
  'email-verification.email-confirmation-failed': {
    id: 'email-verification.email-confirmation-failed',
    defaultMessage: 'We could not confirm your email. Please enter the Verification Code correctly and try again.',
    description: 'Content toastr (popup message) displayed when the user-entered email confirmation/verification failed.',
  },
  'email-verification.email-change-successful': {
    id: 'email-verification.email-change-successful',
    defaultMessage: 'Your email address has been updated.',
    description: 'Content toastr (popup message) displayed when the user-entered email was successfully saved.',
  },
  'email-verification.email-change-failed': {
    id: 'email-verification.email-change-failed',
    defaultMessage: 'We could not update your email address. {email} is part of an existing account. Contact support to remove the existing account.',
    description: 'Content toastr (popup message) displayed when the user-entered email was not saved successfully.',
  },
  'social-accounts.account-disconnect-successful': {
    id: 'social-accounts.account-disconnect-successful',
    defaultMessage: 'Your {socialAccountName} account has been disconnected.',
    description: 'Content of toastr (popup message) when the user\'s social account disconnection succeeded.',
  },
  'social-accounts.account-disconnect-failed': {
    id: 'social-accounts.account-disconnect-failed',
    defaultMessage: 'We could not disconnect your {socialAccountName} account. Please try again.',
    description: 'Content of toastr (popup message) when the user\'s social account disconnection failed.',
  },
  'devices-editor.trusted-device-save-successful': {
    id: 'devices-editor.trusted-device-save-successful',
    defaultMessage: 'Your device has been marked as a trusted device',
    description: 'Content of toastr (popup message) when the user\'s device has been successfully marked as trusted.',
  },
  'devices-editor.trusted-device-save-failed': {
    id: 'devices-editor.trusted-device-save-failed',
    defaultMessage: 'We were unable to mark your device as trusted. Please try again.',
    description: 'Content of toastr (popup message) when the process failed to mark the user\'s device as trusted.',
  },
  'devices-editor.report-device-save-successful': {
    id: 'devices-editor.report-device-save-successful',
    defaultMessage: 'Your device has been reported as a suspicious device.',
    description: 'Content of toastr (popup message) when the user\'s device has been successfully marked as suspicious.',
  },
  'devices-editor.report-device-save-failed': {
    id: 'devices-editor.report-device-save-failed',
    defaultMessage: 'We were unable to report your device as a suspicious device. Please try again.',
    description: 'Content of toastr (popup message) when the process failed to report the user\'s device as suspicious.',
  },
  'enhanced-security.enhanced-security-save-successful': {
    id: 'enhanced-security.enhanced-security-save-successful',
    defaultMessage: 'Your enhanced security setting has been updated.',
    description: 'Content toastr (popup message) displayed when the enhanced security setting was successfully saved.',
  },
  'enhanced-security.enhanced-security-save-failed': {
    id: 'enhanced-security.enhanced-security-save-failed',
    defaultMessage: 'We could not update your enhanced security setting. Please try again.',
    description: 'Content toastr (popup message) displayed when the enhanced security setting was not saved due to an error.',
  },
  'copy-to-clipboard.token-id-copied': {
    id: 'copy-to-clipboard.token-id-copied',
    defaultMessage: 'The token ID has been copied to the clipboard.',
    description: 'Success message to be displayed when the token ID is copied to the clipboard'
  },
  'token-edit.token-update-successful': {
    id: 'token-edit.token-update-successful',
    defaultMessage: 'The token {name} was updated successfully.'
  },
  'token-edit.token-update-failed': {
    id: 'token-edit.token-update-failed',
    defaultMessage: 'We could not update your token. Please try again.'
  },
  'token-edit.token-update-failed-dup-name': {
    id: 'token-edit.token-update-failed-dup-name',
    defaultMessage: 'We could not update your token. The token name entered already exists. Please try again.'
  },
  'token-edit.token-add-successful': {
    id: 'token-edit.token-add-successful',
    defaultMessage: 'New token created. Copy and store your token now. You won\'t be able to access it later.'
  },
  'token-edit.token-add-limit': {
    id: 'token-edit.token-add-limit',
    defaultMessage: 'You’re at the maximum number of tokens. To create a new token, you need to delete an existing one.'
  },
  'token-edit.token-add-failed': {
    id: 'token-edit.token-add-failed',
    defaultMessage: 'We could not add your token. Please try again.'
  },
  'token-edit.token-add-failed-dup-name': {
    id: 'token-edit.token-add-failed-dup-name',
    defaultMessage: 'We could not add your token. The token name entered already exists. Please try again.'
  },
  'token-edit.token-delete-successful': {
    id: 'token-edit.token-delete-successful',
    defaultMessage: 'Your token was deleted.'
  },
  'token-edit.token-delete-failed': {
    id: 'token-edit.token-delete-failed',
    defaultMessage: 'We could not delete your token. Please try again.'
  },
  'token-edit.token-not-found': {
    id: 'token-edit.token-not-found',
    defaultMessage: 'Token not found'
  },

  'token-edit.personal-access-token-create-failed': {
    id: 'token-edit.personal-access-token-create-failed',
    defaultMessage: 'Your token wasn’t created. Try again',
  },
  'token-edit.delete': {
    id: 'token-edit.delete',
    defaultMessage: 'The token {name} was deleted successfully.',
  },

});

export default t;
