import { defineMessages } from 'react-intl';

const t = defineMessages({
  'shared.navbar.app-title': {
    id: 'shared.navbar.app-title',
    defaultMessage: 'Profile',
  },
  'shared.navbar.my-account': {
    id: 'shared.navbar.my-account',
    defaultMessage: 'My Account',
  },
  'shared.cancel': {
    id: 'shared.cancel',
    defaultMessage: 'Cancel',
  },
  'shared.done': {
    id: 'shared.done',
    defaultMessage: 'Done',
  },
  'shared.save': {
    id: 'shared.save',
    defaultMessage: 'Save',
  },
  'shared.saving': {
    id: 'shared.saving',
    defaultMessage: 'Saving...',
  },
  'shared.edit': {
    id: 'shared.edit',
    defaultMessage: 'Edit',
  },
  'shared.close': {
    id: 'shared.close',
    defaultMessage: 'Close',
  },
  'shared.continue': {
    id: 'shared.continue',
    defaultMessage: 'Continue',
  },
  'shared.none-set': {
    id: 'shared.none-set',
    defaultMessage: 'None set',
  },
  'shared.disabled': {
    id: 'shared.disabled',
    defaultMessage: 'Disabled',
  },
  'shared.enabled': {
    id: 'shared.enabled',
    defaultMessage: 'Enabled',
  },
  'shared.enabled-connected-to-device': {
    id: 'shared.enabled-connected-to-device',
    defaultMessage: 'Enabled, connected to device',
  },
  'shared.illegal-character-entry': {
    id: 'shared.illegal-character-entry',
    defaultMessage: 'Enter a value that doesn\'t include <, >, %, &, or brackets.',
  },
  'shared.back': {
    id: 'shared.back',
    defaultMessage: 'Back',
  },
  'shared.confirm': {
    id: 'shared.confirm',
    defaultMessage: 'Confirm',
  },
  'shared.confirming': {
    id: 'shared.confirming',
    defaultMessage: 'Confirming...',
  },
  'shared.help.support': {
    id: 'shared.help.support',
    defaultMessage: 'Support',
  },
  'shared.almost-there': {
    id: 'shared.almost-there',
    defaultMessage: 'Almost There...',
  },
  'shared.email-verification': {
    id: 'shared.email-verification',
    defaultMessage: 'Email verification',
  },
  'shared.connect-to-google': {
    id: 'shared.connect-to-google',
    defaultMessage: 'Connect to Google',
    description: 'Link text for connecting to Google social account',
  },
  'shared.connect-to-facebook': {
    id: 'shared.connect-to-facebook',
    defaultMessage: 'Connect to Facebook',
    description: 'Link text for connecting to Facebook social account',
  },
  'shared.connect-to-linkedin': {
    id: 'shared.connect-to-linkedin',
    defaultMessage: 'Connect to LinkedIn',
    description: 'Link text for connecting to LinkedIn social account',
  },
  'shared.connect-to-microsoft': {
    id: 'shared.connect-to-microsoft',
    defaultMessage: 'Connect to Microsoft',
    description: 'Link text for connecting to Microsoft social account',
  },
  'shared.connect-to-lastpass': {
    id: 'shared.connect-to-lastpass',
    defaultMessage: 'Connect to LastPass',
    description: 'Link text for connecting to LastPass social account',
  },
  'shared.connect-to-apple': {
    id: 'shared.connect-to-apple',
    defaultMessage: 'Connect to Apple',
    description: 'Link text for connecting to Apple social account',
  },
  'shared.or': {
    id: 'shared.or',
    defaultMessage: 'or',
    description: 'Word OR to separate two available actions.',
  },
  'shared.select.prompt': {
    id: 'shared.select.prompt',
    defaultMessage: 'Select...',
    description: 'Used as a prompt text in select dropdown lists to prompt the user to make a selection',
  },
  'shared.personal.info': {
    id: 'shared.personal.info',
    defaultMessage: 'Personal info',
    description: 'Section title for the personal info fields and navbar link',
  },
  'shared.profile.picture': {
    id: 'shared.profile.picture',
    defaultMessage: 'Profile picture',
    description: 'Profile picture section title',
  },
  'shared.name': {
    id: 'shared.name',
    defaultMessage: 'Name',
    description: 'User name key',
  },
  'shared.signin': {
    id: 'shared.signin',
    defaultMessage: 'Sign-in',
    description: 'Heading for social account integeration',
  },

  'nav.navbar.signinsecurity': {
    id: 'nav.navbar.signinsecurity',
    defaultMessage: 'Sign In & security',
    description: 'The nav bar Sign In & Security link',
  },
  'nav.navbar.developerTools': {
    id: 'nav.navbar.developerTools',
    defaultMessage: 'Developer tools',
    description: 'The nav bar Developer Tools link',
  },
  'shared.permissionItem.profile.title': {
    id: 'shared.permissionItem.profile.title',
    defaultMessage: 'Profile',
    description: 'Title for profile applications tile',
  },
  'shared.permissionItem.profile.subtitle': {
    id: 'shared.permissionItem.profile.subtitle',
    defaultMessage: 'For an authenticated user',
    description: 'Subtitle for profile applications tile',
  },
  'shared.permissionItem.profile.identity:read': {
    id: 'shared.permissionItem.profile.identity:read',
    defaultMessage: 'Get user information',
    description: 'Text for profile default scope',
  },
  'shared.permissionItem.profile.identity:read.cbtitle': {
    id: 'shared.permissionItem.profile.identity:read.cbtitle',
    defaultMessage: 'Get user information',
    description: 'Text for profile default scope checkbox title',
  },
  'shared.permissionItem.profile.identity:scim.me': {
    id: 'shared.permissionItem.profile.identity:scim.me',
    defaultMessage: 'Modify user details',
    description: 'Text for profile - modify user details scope',
  },
  'shared.permissionItem.profile.identity:scim.me.cbtitle': {
    id: 'shared.permissionItem.profile.identity:scim.me.cbtitle',
    defaultMessage: 'Modify user details',
    description: 'Text for profile - modify user details scope checkbox title',
  },
  'shared.permissionItem.collaboration.title': {
    id: 'shared.permissionItem.collaboration.title',
    defaultMessage: 'GoToMeeting, GoToWebinar, or GoToTraining',
    description: 'Title for collaboration applications tile',
  },
  'shared.permissionItem.collaboration.subtitle': {
    id: 'shared.permissionItem.collaboration.subtitle',
    defaultMessage: 'To create, start, and modify sessions for remote collaboration',
    description: 'Subtitle for collaboration applications tile',
  },
  'shared.permissionItem.collaboration.cbtitle': {
    id: 'shared.permissionItem.collaboration.cbtitle',
    defaultMessage: 'GoToMeeting, GoToWebinar, GoToTraining',
    description: 'Checkbox title for collaboration applications tile',
  },
  'shared.permissionItem.support.title': {
    id: 'shared.permissionItem.support.title',
    defaultMessage: 'GoToAssist Remote Support or Service Desk',
    description: 'Title for support applications tile',
  },
  'shared.permissionItem.support.subtitle': {
    id: 'shared.permissionItem.support.subtitle',
    defaultMessage: 'To create, start, and modify sessions for real-time support',
    description: 'Subtitle for support applications tile',
  },
  'shared.permissionItem.support.cbtitle': {
    id: 'shared.permissionItem.support.cbtitle',
    defaultMessage: 'GoToAssist Remote Support or Service Desk',
    description: 'Checkbox title for support applications tile',
  },
  'shared.permissionItem.scim.title': {
    id: 'shared.permissionItem.scim.title',
    defaultMessage: 'SCIM',
    description: 'Title for scim applications tile',
  },
  'shared.permissionItem.scim.subtitle': {
    id: 'shared.permissionItem.scim.subtitle',
    defaultMessage: 'For automated user management',
    description: 'Subtitle for scim applications tile',
  },
  'shared.permissionItem.scim.cbtitle': {
    id: 'shared.permissionItem.scim.cbtitle',
    defaultMessage: 'SCIM',
    description: 'Checkbox title for SCIM applications tile',
  },
  'shared.permissionItem.admincenter.title': {
    id: 'shared.permissionItem.admincenter.title',
    defaultMessage: 'Admin Center',
    description: 'Title for admin center applications tile',
  },
  'shared.permissionItem.admincenter.subtitle': {
    id: 'shared.permissionItem.admincenter.subtitle',
    defaultMessage: 'To manage users across GoTo products',
    description: 'Subtitle for admin center applications tile',
  },
  'shared.permissionItem.admincenter.cbtitle': {
    id: 'shared.permissionItem.admincenter.cbtitle',
    defaultMessage: 'Admin Center',
    description: 'Subtitle for admin center applications tile',
  },
  'shared.permissionItem.jive.title': {
    id: 'shared.permissionItem.jive.title',
    defaultMessage: 'GoToConnect',
    description: 'Title for GoToConnect applications tile',
  },
  'shared.permissionItem.jive.subtitle': {
    id: 'shared.permissionItem.jive.subtitle',
    defaultMessage: 'For initiating phone calls and other telephony services',
    description: 'Subtitle for GoToConnect applications tile',
  },
  'shared.permissionItem.jive.cbtitle': {
    id: 'shared.permissionItem.jive.cbtitle',
    defaultMessage: 'All scopes for GoToConnect',
    description: 'Checkbox title for GoToConnect applications tile',
  },
  'shared.permissionItem.jive.calls.v2.initiate': {
    id: 'shared.permissionItem.jive.calls.v2.initiate',
    defaultMessage: 'Start a call on your phone line',
    description: 'Text for granular permission: Start a call on your phone line',
  },
  'shared.permissionItem.jive.calls.v2.initiate.cbtitle': {
    id: 'shared.permissionItem.jive.calls.v2.initiate.cbtitle',
    defaultMessage: 'Start a call on your phone line',
    description: 'Text for granular permission: Start a call on your phone line checkbox title',
  },
  'shared.permissionItem.jive.messaging.v1.read': {
    id: 'shared.permissionItem.jive.messaging.v1.read',
    defaultMessage: 'Access your messages and media',
    description: 'Text for granular permission: Access your messages and media',
  },
  'shared.permissionItem.jive.messaging.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.messaging.v1.read.cbtitle',
    defaultMessage: 'Access your messages and media',
    description: 'Text for granular permission: Access your messages and media',
  },
  'shared.permissionItem.jive.messaging.v1.write': {
    id: 'shared.permissionItem.jive.messaging.v1.write',
    defaultMessage: 'Update or delete messages',
    description: 'Text for granular permission: Update or delete messages',
  },
  'shared.permissionItem.jive.messaging.v1.write.cbtitle': {
    id: 'shared.permissionItem.jive.messaging.v1.write.cbtitle',
    defaultMessage: 'Update or delete messages',
    description: 'Text for granular permission: Update or delete messages',
  },
  'shared.permissionItem.jive.messaging.v1.send': {
    id: 'shared.permissionItem.jive.messaging.v1.send',
    defaultMessage: 'Send messages and media on your behalf',
    description: 'Text for granular permission: Send messages and media on your behalf',
  },
  'shared.permissionItem.jive.messaging.v1.send.cbtitle': {
    id: 'shared.permissionItem.jive.messaging.v1.send.cbtitle',
    defaultMessage: 'Send messages and media on your behalf',
    description: 'Text for granular permission: Send messages and media on your behalf',
  },
  'shared.permissionItem.jive.messaging.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.messaging.v1.notifications.manage',
    defaultMessage: 'Manage notifications for messages and media',
    description: 'Text for granular permission: Manage notifications for messages and media',
  },
  'shared.permissionItem.jive.messaging.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.messaging.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notifications for messages and media',
    description: 'Text for granular permission: Manage notifications for messages and media',
  },
  'shared.permissionItem.jive.cr.v1.read': {
    id: 'shared.permissionItem.jive.cr.v1.read',
    defaultMessage: 'Access call history for phone lines in the PBX',
    description: 'Text for granular permission: Access call history for phone lines in the PBX',
  },
  'shared.permissionItem.jive.cr.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.cr.v1.read.cbtitle',
    defaultMessage: 'Access call history for phone lines in the PBX',
    description: 'Text for granular permission: Access call history for phone lines in the PBX checkbox title',
  },
  'shared.permissionItem.jive.users.v1.lines.read': {
    id: 'shared.permissionItem.jive.users.v1.lines.read',
    defaultMessage: 'Retrieve your phone line information',
    description: 'Text for granular permission: Retrieve your phone line information',
  },
  'shared.permissionItem.jive.users.v1.lines.read.cbtitle': {
    id: 'shared.permissionItem.jive.users.v1.lines.read.cbtitle',
    defaultMessage: 'Retrieve your phone line information',
    description: 'Text for granular permission: Retrieve your phone line information checkbox title',
  },
  'shared.permissionItem.jive.webrtc.v1.read': {
    id: 'shared.permissionItem.jive.webrtc.v1.read',
    defaultMessage: 'Retrieve information about web calls',
    description: 'Text for granular permission: Retrieve information about web calls',
  },
  'shared.permissionItem.jive.webrtc.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.webrtc.v1.read.cbtitle',
    defaultMessage: 'Retrieve information about web calls',
    description: 'Text for granular permission: Retrieve information about web calls checkbox title',
  },
  'shared.permissionItem.jive.webrtc.v1.write': {
    id: 'shared.permissionItem.jive.webrtc.v1.write',
    defaultMessage: 'Perform actions on web calls (such as create, answer...)',
    description: 'Text for granular permission: Perform actions on web calls (such as create, answer...)',
  },
  'shared.permissionItem.jive.webrtc.v1.write.cbtitle': {
    id: 'shared.permissionItem.jive.webrtc.v1.write.cbtitle',
    defaultMessage: 'Perform actions on web calls (such as create, answer...)',
    description: 'Text for granular permission: Perform actions on web calls (such as create, answer...) checkbox title',
  },
  'shared.permissionItem.jive.call-control.v1.calls.control': {
    id: 'shared.permissionItem.jive.call-control.v1.calls.control',
    defaultMessage: 'Control various features associated with a call',
    description: 'Text for granular permission: Control various features associated with a call',
  },
  'shared.permissionItem.jive.call-control.v1.calls.control.cbtitle': {
    id: 'shared.permissionItem.jive.call-control.v1.calls.control.cbtitle',
    defaultMessage: 'Control various features associated with a call',
    description: 'Text for granular permission: Control various features associated with a call checkbox title',
  },
  'shared.permissionItem.jive.fax.v1.read': {
    id: 'shared.permissionItem.jive.fax.v1.read',
    defaultMessage: 'Access faxes',
    description: 'Text for granular permission: Access faxes',
  },
  'shared.permissionItem.jive.fax.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.fax.v1.read.cbtitle',
    defaultMessage: 'Access faxes',
    description: 'Text for granular permission: Access faxes checkbox title',
  },
  'shared.permissionItem.jive.fax.v1.write': {
    id: 'shared.permissionItem.jive.fax.v1.write',
    defaultMessage: 'Update or delete faxes',
    description: 'Text for granular permission: Update or delete faxes',
  },
  'shared.permissionItem.jive.fax.v1.write.cbtitle': {
    id: 'shared.permissionItem.jive.fax.v1.write.cbtitle',
    defaultMessage: 'Update or delete faxes',
    description: 'Text for granular permission: Update or delete faxes checkbox title',
  },
  'shared.permissionItem.jive.fax.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.fax.v1.notifications.manage',
    defaultMessage: 'Manage notifications for faxes',
    description: 'Text for granular permission: Manage notifications for faxes',
  },
  'shared.permissionItem.jive.fax.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.fax.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notifications for faxes',
    description: 'Text for granular permission: Manage notifications for faxes checkbox title',
  },
  'shared.permissionItem.jive.voicemail.v1.voicemails.read': {
    id: 'shared.permissionItem.jive.voicemail.v1.voicemails.read',
    defaultMessage: 'Read your voicemails',
    description: 'Text for granular permission: Read your voicemails',
  },
  'shared.permissionItem.jive.voicemail.v1.voicemails.read.cbtitle': {
    id: 'shared.permissionItem.jive.voicemail.v1.voicemails.read.cbtitle',
    defaultMessage: 'Read your voicemails',
    description: 'Text for granular permission: Read your voicemails checkbox title',
  },
  'shared.permissionItem.jive.voicemail.v1.voicemails.write': {
    id: 'shared.permissionItem.jive.voicemail.v1.voicemails.write',
    defaultMessage: 'Update/Delete your voicemails',
    description: 'Text for granular permission: Update/Delete your voicemails',
  },
  'shared.permissionItem.jive.voicemail.v1.voicemails.write.cbtitle': {
    id: 'shared.permissionItem.jive.voicemail.v1.voicemails.write.cbtitle',
    defaultMessage: 'Update/Delete your voicemails',
    description: 'Text for granular permission: Update/Delete your voicemails checkbox title',
  },
  'shared.permissionItem.jive.voicemail.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.voicemail.v1.notifications.manage',
    defaultMessage: 'Manage notification subscriptions for voicemails',
    description: 'Text for granular permission: Manage notification subscriptions for voicemails',
  },
  'shared.permissionItem.jive.voicemail.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.voicemail.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notification subscriptions for voicemails',
    description: 'Text for granular permission: Manage notification subscriptions for voicemails checkbox title',
  },
  'shared.permissionItem.jive.call-history.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.call-history.v1.notifications.manage',
    defaultMessage: 'Manage notification subscriptions for call history',
    description: 'Text for granular permission: Manage notification subscriptions for call history',
  },
  'shared.permissionItem.jive.call-history.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.call-history.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notification subscriptions for call history',
    description: 'Text for granular permission: Manage notification subscriptions for call history',
  },
  'shared.permissionItem.jive.voice-admin.v1.read': {
    id: 'shared.permissionItem.jive.voice-admin.v1.read',
    defaultMessage: 'Access phone numbers within an account',
    description: 'Text for granular permission: Access phone numbers within an account',
  },
  'shared.permissionItem.jive.voice-admin.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.voice-admin.v1.read.cbtitle',
    defaultMessage: 'Access phone numbers within an account',
    description: 'Text for granular permission: Access phone numbers within an account checkbox title',
  },
  'shared.permissionItem.jive.voice-admin.v1.write': {
    id: 'shared.permissionItem.jive.voice-admin.v1.write',
    defaultMessage: 'Modify voice entities like phone numbers, devices and extensions',
    description: 'Text for granular permission: Modify voice entities like phone numbers, devices and extensions',
  },
  'shared.permissionItem.jive.voice-admin.v1.write.cbtitle': {
    id: 'shared.permissionItem.jive.voice-admin.v1.write.cbtitle',
    defaultMessage: 'Modify voice entities like phone numbers, devices and extensions',
    description: 'Text for granular permission: Modify voice entities like phone numbers, devices and extensions',
  },
  'shared.permissionItem.jive.presence.v1.read': {
    id: 'shared.permissionItem.jive.presence.v1.read',
    defaultMessage: 'Read presence for self and other users',
    description: 'Text for granular permission: Read presence for self and other users',
  },
  'shared.permissionItem.jive.presence.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.presence.v1.read.cbtitle',
    defaultMessage: 'Read presence for self and other users',
    description: 'Text for granular permission: Read presence for self and other users checkbox title',
  },
  'shared.permissionItem.jive.presence.v1.write': {
    id: 'shared.permissionItem.jive.presence.v1.write',
    defaultMessage: 'Write self user presence including "Do Not Disturb"',
    description: 'Text for granular permission: Write self user presence including "Do Not Disturb"',
  },
  'shared.permissionItem.jive.presence.v1.write.cbtitle': {
    id: 'shared.permissionItem.jive.presence.v1.write.cbtitle',
    defaultMessage: 'Write self user presence including "Do Not Disturb"',
    description: 'Text for granular permission: Write self user presence including "Do Not Disturb" checkbox title',
  },
  'shared.permissionItem.jive.presence.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.presence.v1.notifications.manage',
    defaultMessage: 'Manage notification subscriptions for presence',
    description: 'Text for granular permission: Manage notification subscriptions for presence',
  },
  'shared.permissionItem.jive.presence.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.presence.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notification subscriptions for presence',
    description: 'Text for granular permission: Manage notification subscriptions for presence checkbox title',
  },
  'shared.permissionItem.jive.call-events.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.call-events.v1.notifications.manage',
    defaultMessage: 'Manage notification subscriptions for call events',
    description: 'Text for granular permission: Manage notification subscriptions for call events',
  },
  'shared.permissionItem.jive.call-events.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.call-events.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notification subscriptions for call events',
    description: 'Text for granular permission: Manage notification subscriptions for call events checkbox title',
  },
  'shared.permissionItem.jive.call-events.v1.events.read': {
    id: 'shared.permissionItem.jive.call-events.v1.events.read',
    defaultMessage: 'Retrieve call events',
    description: 'Text for granular permission: Retrieve call events',
  },
  'shared.permissionItem.jive.call-events.v1.events.read.cbtitle': {
    id: 'shared.permissionItem.jive.call-events.v1.events.read.cbtitle',
    defaultMessage: 'Retrieve call events',
    description: 'Text for granular permission: Retrieve call events checkbox title',
  },
  'shared.permissionItem.jive.recording.v1.notifications.manage': {
    id: 'shared.permissionItem.jive.recording.v1.notifications.manage',
    defaultMessage: 'Manage notification subscriptions for recordings',
    description: 'Text for granular permission: Manage notification subscriptions for recordings',
  },
  'shared.permissionItem.jive.recording.v1.notifications.manage.cbtitle': {
    id: 'shared.permissionItem.jive.recording.v1.notifications.manage.cbtitle',
    defaultMessage: 'Manage notification subscriptions for recordings',
    description: 'Text for granular permission: Manage notification subscriptions for recordings checkbox title',
  },
  'shared.permissionItem.jive.recording.v1.read': {
    id: 'shared.permissionItem.jive.recording.v1.read',
    defaultMessage: 'Retrieve call recordings and transcripts',
    description: 'Text for granular permission: Grants read access to all resources in the API',
  },
  'shared.permissionItem.jive.recording.v1.read.cbtitle': {
    id: 'shared.permissionItem.jive.recording.v1.read.cbtitle',
    defaultMessage: 'Retrieve call recordings and transcripts',
    description: 'Text for granular permission: Grants read access to all resources in the API checkbox title',
  },
  'shared.copy-token-id': {
    id: 'shared.copy-token-id',
    defaultMessage: 'Copy token'
  },
  'shared.copy': {
    id: 'shared.copy',
    defaultMessage: 'Copy'
  },
  'shared.enterprise-signin.enterprise-sign-in': {
    id: 'shared.enterprise-signin.enterprise-sign-in',
    defaultMessage: 'Enterprise Sign In',
    description: 'Title string for the Enterprise Sign In section',
  },
});

export default t;
